
.fl-uppercase:first-letter {
    text-transform: uppercase;
}


.swiper-button-next::after {
    display: none;
}

.swiper-button-prev::after {
    display: none;
}

@font-face {
    font-family: 'f55_icons';
    src: url('./f55_icons.ttf?iq7xo7') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'f55';
    src: url('./HemiHeadFormula55-BoldItalic.ttf?iq7xo7') format('truetype');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'f55_icons', serif !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.icon-qatar_2022:before {
    content: "\e900";
}

.icon-euro_2024:before {
    content: "\e944";
}

.icon-football_penalty:before {
    content: "\e943";
}

.icon-valorant:before {
    content: "\e901";
}

.icon-starcraft1:before {
    content: "\e902";
}

.icon-starcraft2:before {
    content: "\e903";
}

.icon-rainbow6:before {
    content: "\e904";
}

.icon-overwatch:before {
    content: "\e905";
}

.icon-csgo:before {
    content: "\e906";
}

.icon-rocketleague:before {
    content: "\e93c";
}

.icon-culture:before {
    content: "\e912";
}

.icon-cyberrugby:before {
    content: "\e937";
}

.icon-cybertennis:before {
    content: "\e938";
}

.icon-cyberbasketball:before {
    content: "\e939";
}

.icon-angle-down:before {
    content: "\e90f";
}

.icon-angle-left:before {
    content: "\e910";
}

.icon-angle-right:before {
    content: "\e913";
}

.icon-angle-top:before {
    content: "\e914";
}

.icon-cyberfootball:before {
    content: "\e915";
}

.icon-cyberhockey:before {
    content: "\e916";
}

.icon-beach_volleyball:before {
    content: "\e917";
}

.icon-politics:before {
    content: "\e918";
}

.icon-beach_football:before {
    content: "\e927";
}

.icon-auto_race:before {
    content: "\e928";
}

.icon-floorball:before {
    content: "\e929";
}

.icon-ski_race:before {
    content: "\e92a";
}

.icon-transfers:before {
    content: "\e92c";
}

.icon-skiing:before {
    content: "\e92d";
}

.icon-biathlon:before {
    content: "\e92e";
}

.icon-weather:before {
    content: "\e931";
}

.icon-ski_jumping:before {
    content: "\e933";
}

.icon-water_polo:before {
    content: "\e934";
}

.icon-bandy:before {
    content: "\e935";
}

.icon-snooker:before {
    content: "\e936";
}

.icon-soccer:before {
    content: "\e90e";
}

.icon-motor_racing:before {
    content: "\e907";
}

.icon-golf:before {
    content: "\e908";
}

.icon-cricket:before {
    content: "\e90c";
}

.icon-chess:before {
    content: "\e909";
}

.icon-darts:before {
    content: "\e90a";
}

.icon-formula1:before {
    content: "\e90b";
}

.icon-fifa_world_cup:before {
    content: "\e90d";
}

.icon-hockey:before {
    content: "\e911";
}

.icon-uefa:before {
    content: "\e919";
}

.icon-table_tennis:before {
    content: "\e91a";
}

.icon-swiming:before {
    content: "\e91b";
}

.icon-beach_soccer:before {
    content: "\e91c";
}

.icon-futsal:before {
    content: "\e924";
}

.icon-handball:before {
    content: "\e91d";
}

.icon-cyberhandball:before {
    content: "\e93a";
}

.icon-basketball:before {
    content: "\e91e";
}

.icon-volleyball:before {
    content: "\e91f";
}

.icon-cybervolleyball:before {
    content: "\e93b";
}

.icon-tennis:before {
    content: "\e920";
}

.icon-football:before {
    content: "\e921";
}

.icon-cybersport:before {
    content: "\e922";
}

.icon-uefa_champion_league:before {
    content: "\e923";
}

.icon-mma:before {
    content: "\e925";
}

.icon-boxing:before {
    content: "\e926";
}

.icon-badminton:before {
    content: "\e92b";
}

.icon-baseball:before {
    content: "\e92f";
}

.icon-field_hockey:before {
    content: "\e930";
}

.icon-billiards:before {
    content: "\e932";
}

.icon-rugby:before {
    content: "\e93d";
}

.icon-aussie_football:before {
    content: "\e93e";
}

.icon-podium:before {
    content: "\e93f";
}

.icon-exactscore:before {
    content: "\e940";
}

.icon-jackpot:before {
    content: "\e941";
}

.icon-cash_back:before {
    content: "\e942";
}

.icon-support:before {
    content: "\e945";
}

.icon-olympiad:before {
    content: "";
    height: 18px;
    width: 18px;
    display: block;
    background-size: 18px 18px;
    background-image: url("./olymp-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-clip: revert;
}

.icon-exclusive:before {
    content: " ";
    height: 18px;
    width: 18px;
    display: block;
    background-size: 18px 14px;
    background-image: url("./exclusive-icon.png");
    background-repeat: no-repeat;
    background-position: center;
}
